.main {
  max-width: 1140px;
  margin: 0 auto;
  padding-top: 38px;
}
.breadcrumb {
  margin: 0 0 30px;
  padding: 8px 0;
  border: none;
  background: none;
  font-size: 12px;
  line-height: 20px;
}
.breadcrumb > li {
  text-shadow: none;
  padding: 0;
  position: relative;
  white-space: nowrap;
  display: inline-block;
}
.breadcrumb > li:after {
    content: '>';
    display: block;
    position: absolute;
    top: 1px;
    left: -10px;
    width: auto;
    height: auto;
    border: none;
    transform: rotate(0deg);
    color: #a0a0a0;
}
.breadcrumb > li:first-child:after {
  display: none;
}
.breadcrumb > li + li {
  margin-left: 10px;
}
.breadcrumb > li a{
  color: #a0a0a0;
}
.breadcrumb > li:last-child {
  pointer-events: none;
}
a:focus, a:hover {
    color: #ff9400;
    text-decoration: underline;
}
.product-section {
  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
  }
  &__title {
    font-size: 25px;
    line-height: 27px;
    font-weight: 700;
    color: #232323;
    margin: 0;
  }
  &__vendor {
    font-size: 14px;
    line-height: 16px;
    color: #7b7b7b;
  }
  &__stock {
    color: #353535;
    font-size: 14px;
    line-height: 16px;
    padding-left: 18px;
    position: relative;
    margin-bottom: 17px;
    &::after {
      content:'';
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 9px;
      height: 9px;
      border-radius: 50%;
    }
    &.in-stock::after {
      background: #66ba22;
    }
    &.out-stock::after {
      background: #f00;
    }
  }
}
.rating a {
  color: #f4a50d;
  font-size: 14px;
  line-height: 16px;
  text-decoration: underline;
  margin-left: 10px;
  @media screen and (min-width: 1250px) {
    &:hover {
      text-decoration: none;
    }
  }
}
.product-page {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 100px;
  &__slider-box {
    position: relative;
    max-width: 488px;
    width: 100%;
    flex-basis: 488px;
    min-width: 0;
    margin-right: 65px;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    @include response(1249px) {
      margin-right: 40px;
    }
    @include response(1023px) {
      flex-basis: auto;
      margin: 0 auto 40px;
    }
    @include response(767px) {
      flex-direction: column-reverse;
      align-items: center;
    }
  }
  &__lg-slider {
    max-width: 400px;
    width: 100%;
    flex-basis: 400px;
    min-width: 0;
    @include response(767px) {
      flex-basis: auto;
    }
  }
  &__lg-slide {
    width: 100%;
    height: 400px;
    display: flex !important;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1px solid #e7e7e7;
    text-decoration: none;
    border-radius: 5px;
    @include response(767px) {
      height: auto;
    }
  }
  &__sm-slider {
    max-width: 70px;
    width: 100%;
    margin-right: 10px;
    padding: 30px 0;
    @include response(767px) {
      max-width: 100%;
      margin: 0 auto;
    }
  }
  &__sm-slide {
    display: flex !important;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 70px;
    height: 70px !important;
    margin: 6px 0;
    border: 1px solid rgba(231, 231, 231, 1);
    transition: .3s ease-in-out;
    cursor: pointer;
    @include response(767px) {
      margin: 0 6px;
    }
    &.slick-slide {
      opacity: 0.5;
      border: 1px solid rgba(231, 231, 231, 1);
      transition: .3s ease-in-out;
      @media screen and (min-width: 1250px) {
        &:hover {
          opacity: 1;
        }
      }
    }
    &.slick-current.slick-active {
      opacity: 1;
      border-color: #c1e6fb;
    }
  }
  &__arrow {
    width: 14px;
    height: 14px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
    svg {
      width: 14px;
      height: 14px;
    }
    &.prev-arrow {
      top: 0;
      transform: rotate(90deg);
    }
    &.next-arrow {
      bottom: 0;
      transform: rotate(90deg);
    }
  }
  &__info {
    background: #f8f8f8;
    max-width: 587px;
    flex-basis: 587px;
    width: 100%;
    padding: 35px 29px;
    border-radius: 10px;
  }
  &__info-title {
    font-size: 16px;
    line-height: 20px;
    color: #232323;
    font-weight: 600;
    margin-bottom: 18px;
  }
  &__info-text {
    font-size: 14px;
    line-height: 22px;
    color: #414141;
    margin-bottom: 20px;
    p {
      margin: 0;
    }
  }
  &__info-price-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 26px;
  }
  &__info-price {
    font-size: 38px;
    line-height: 40px;
    color: #3a3a3a;
    font-weight: 700;
  }
  &__info-quantity {
    display: flex;
    align-items: center;
    .control-label {
      margin-right: 14px;
      font-size: 14px;
      line-height: 22px;
      color: #414141;
    }
    .form-control {
      border-radius: 5px;
      height: 35px;
      text-align: center;
      border: none;
      width: 83px;
    }
  }
  &__info-buttons {
    display: flex;
    justify-content: space-between;
    #button-cart {
      background: #ff9400;
      border: none;
      max-width: 261px;
      width: 100%;
      border-radius: 5px;
      font-size: 14px;
      line-height: 50px;
      padding: 0;
      color: #fff;
      text-transform: uppercase;
      position: relative;
      font-weight: 500;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      .cart-icon {
        background: url('../images/cart-icon.png') no-repeat;
        width: 17px;
        height: 17px;
        margin: -3px 15px 0 0;
      }
      @media screen and (min-width: 1250px) {
        &:hover {
          background: #ea9114;
        }
      }
    }
    .compare-btn {
      background: none;
      border: 1px solid #ff9400;
      max-width: 261px;
      width: 100%;
      border-radius: 5px;
      font-size: 14px;
      line-height: 50px;
      padding: 0;
      color: #ff9400;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      @media screen and (min-width: 1250px) {
        &:hover {
          background: #fff;
        }
      }
    }
    .compare-icon {
      background: url('../images/compare-ico.png') no-repeat;
      width: 21px;
      height: 19px;
      margin-right: 15px;
    }
  }
}
.product-information {
  display: flex;
  justify-content: space-between;
  &__box {
    max-width: 520px;
    flex-basis: 520px;
    width: 100%;
  }
  &__title {
    font-size: 18px;
    line-height: 20px;
    font-weight: 600;
    color: #4c4c4c;
    margin-bottom: 28px;
  }
  &__text {
    font-size: 14px;
    line-height: 20px;
    ol {
      padding-left: 15px;
    }
  }
  &__review {
    flex-basis: 556px;
    max-width: 556px;
    width: 100%;
    input {
      background: #f6f6f6;
      width: 100%;
      height: 51px;
      border: none;
      border-radius: 5px;
      padding: 0 26px;
      outline: none;
      box-shadow: none;
      font-family: 'Open sans';
    }
    textarea {
      background: #f6f6f6;
      width: 100%;
      height: 100px;
      resize: none;
      border: none;
      border-radius: 5px;
      padding: 15px 26px;
      outline: none;
      box-shadow: none;
      font-family: 'Open sans';
    }
    .help-block {
      font-size: 12px;
      line-height: 14px;
      padding: 15px 0;
    }
    .form-group {
      margin: 0 0 20px;
    }
  }
  .buttons {
    margin: 0;
    display: flex;
    justify-content: flex-end;
  }
  #button-review {
    background: #ff9400;
    border: none;
    width: 200px;
    border-radius: 5px;
    font-size: 14px;
    line-height: 50px;
    padding: 0;
    color: #fff;
    text-transform: uppercase;
    position: relative;
    font-weight: 500;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;
    @media screen and (min-width: 1250px) {
      &:hover {
        background: #ea9114;
      }
    }
  }
}
#review {
  margin-top: 35px;
  .table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
    font-size: 14px;
    p {
      margin: 15px 0 0;
    }
    & + .table {
      border-top: 1px solid #eee;
      padding-top: 20px;
    }
  }
  .text-right {
    font-size: 14px;
    line-height: 16px;
    color: #606060;
    text-align: right;
  }
  strong {
    color: #606060;
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    margin-right: 15px;
  }
  .table-bordered>tbody>tr>td, .table-bordered>tbody>tr>th, .table-bordered>tfoot>tr>td, .table-bordered>tfoot>tr>th, .table-bordered>thead>tr>td, .table-bordered>thead>tr>th {
    border: none;
  }
  .table>tbody>tr>td, .table>tbody>tr>th, .table>tfoot>tr>td, .table>tfoot>tr>th, .table>thead>tr>td, .table>thead>tr>th {
    padding: 0;
  }
}
.rating-section {
  background: #f6f6f6;
  padding: 15px 0;
  border-radius: 5px;
  .control-label {
    font-size: 14px;
    margin-bottom: 10px;
    display: block;
    text-align: center;
    text-transform: uppercase;
  }
  &__box {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    input {
      width: 16px;
      height: 16px;
      margin: 0 5px;
    }
  }
}
