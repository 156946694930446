body {
  height: 100%;
  font-family: sans-serif;
  font-family: 'Open Sans', sans-serif;
  background: #fff;
}
* {
  box-sizing: border-box;
  &::after,
  &::before {
    box-sizing: border-box;
  }
}
.wrapper {
  width: 100%;
  overflow: hidden;
}
.header {
  &__top {
    border-bottom: 1px solid #e4e4e4;
    padding: 20px 0;
    @media screen and (max-width: 767px) {
      background: #fff;
      padding: 6px 0;
    }
  }
  &__top-box {
    max-width: 1140px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: 767px) {
      flex-wrap: wrap;
      padding: 0 15px;
    }
  }
  &__top-left {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: 767px) {
      flex-wrap: wrap;
    }
  }
  .language-select {
    color: #b8b8b8;
    padding: 0;
    border: none;
    background: none;
    text-transform: uppercase;
    position: relative;
    cursor: pointer;
    @media screen and (min-width: 1250px) {
      &:hover {
        color: #292929;
      }
    }
    & + .language-select {
      margin-left: 18px;
      &::after {
        content:'';
        position: absolute;
        top: 50%;
        margin-top: -8px;
        left: -11px;
        width: 1px;
        height: 14px;
        background: #7c7c7c;
      }
    }
    &.active {
      color: #292929;
    }
  }
  &__phone {
    margin-left: 34px;
    font-size: 14px;
    line-height: 16px;
    text-decoration: none;
    color: #6d6d6d;
  }
  .list-inline {
    margin: 0;
    padding: 0 0 0 30px;
    list-style: none;
    display: flex;
    align-items: center;
    @media screen and (max-width: 767px) {
      padding: 20px 0;
    }
    li + li {
      margin-left: 23px;
      @media screen and (max-width: 767px) {
        margin-left: 10px;
      }
    }
    a {
      color: #6d6d6d;
      font-size: 14px;
      line-height: 16px;
      text-decoration: none;
      display: block;
      @media screen and (min-width: 1250px) {
        &:hover {
          color: #ff9400;
        }
      }
    }
  }
  &__top-right {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    line-height: 16px;
    @media screen and (max-width: 767px) {
      width: 100%;
      display: none;
    }
  }
  &__custom-link {
    color: #ff9400;
    font-weight: 700;
    text-decoration: none;
    @media screen and (min-width: 1250px) {
      &:hover {
        text-decoration: underline;
      }
    }
  }
  &__holder {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1140px;
    margin: 0 auto;
    padding: 20px 0 43px;
    position: relative;
    @media screen and (max-width: 767px) {
      display: block;
      padding: 20px 15px 0;
    }
  }
  &__holder-left {
    display: flex;
    align-items: center;
    max-width: 820px;
    flex-basis: 820px;
    width: 100%;
    @media screen and (max-width: 767px) {
      display: block;
    }
  }
  #logo {
    margin: 0 20px 0 0;
    @media screen and (max-width: 767px) {
      flex-shrink: 0;
    }
  }
}
#top-links {
  @media screen and (max-width: 767px) {
    display: none;
  }
}
#search .input-lg {
    height: 40px;
    line-height: 20px;
    padding: 0 10px;
}
#cart {
  @media screen and (max-width: 767px) {
    position: absolute;
    top: 30px;
    right: 0;
  }
  button {
    display: flex;
    align-items: center;
    background: none;
    border: none;
    color: #6d6d6d;
  }
}
.cart-icon {
  margin-right: 15px;
  .fa-shopping-cart:before {
    color: #3f3f3f;
  }
}
.cart-info {
  border-left: 1px solid #dcdcdc;
  padding-left: 22px;
  &__title {
    display: block;
    text-align: left;
    font-size: 14px;
    line-height: 16px;
    color: #363636;
    font-weight: 600;
    margin-bottom: 7px;
  }
  &__price {
    color: #ff9400;
    font-weight: 600;
  }
}
#menu {
  background: #ecb682;
  text-transform: uppercase;
  border: none;
  border-radius: 0;
  padding: 15px;
  margin: 0;
  .dropdown-menu li > a:hover {
    background: #ecb682;
  }
  .navbar-nav {
    float: none;
    max-width: 1140px;
    margin: 0 auto;
  }
  .navbar-collapse {
    padding: 0;
  }
}
.search-field {
  max-width: 604px;
  flex-basis: 604px;
  width: 100%;
  position: relative;
  input {
    height: 57px;
    border: solid #e3e3e3;
    border-width: 0 0 1px;
    width: 100%;
    font-size: 14px;
    padding: 0 0 0 52px;
    background: none;
    outline: none;
  }
  &__btn {
    position: absolute;
    top: 0;
    left: 0;
  }
  button {
    background: none;
    padding: 0;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    height: 56px;
    width: 52px;
    .fa-search:before {
      color: #d6d6d6;
    }
  }
}
#search .btn-lg {
  box-shadow: none;
  text-shadow: none;
}
.authorization-list {
  margin: 0 0 0 30px;
  padding:0;
  list-style: none;
  display: flex;
  li {
    position: relative;
    & + li {
      margin-left: 30px;
      &::after {
        content:'';
        position: absolute;
        top: 50%;
        margin-top: -7px;
        left: -15px;
        width: 1px;
        height: 14px;
        background: #7c7c7c;
      }
    }
  }
  a {
    color: #6d6d6d;
    font-size: 14px;
    line-height: 16px;
    text-decoration: none;
    @media screen and (min-width: 1250px) {
      &:hover {
        color: #ff9400;
      }
    }
  }
}
.recipes {
  background: url('../images/bg-text.jpg') no-repeat;
  background-size: cover;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 80px 15px;
  &::after {
    content:'';
    position: absolute;
    top:0;
    left:0;
    bottom: 0;
    right: 0;
    background: rgba(235, 161, 60, 0.8);
  }
  &__holder {
    position: relative;
    z-index: 1;
    max-width: 655px;
    margin: 0 auto;
    color: #fff;
    text-align: center;
  }
  &__title {
    font-size: 43px;
    line-height: 45px;
    color: #fff;
    font-weight: 700;
    margin-bottom: 35px;
  }
  &__text {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 35px;
    p {
      margin: 0;
    }
  }
  &__btn-box {
    display: flex;
    justify-content: center;
  }
  &__btn {
    border: 2px solid #fff;
    border-radius: 30px;
    max-width: 292px;
    width: 100%;
    flex-basis: 292px;
    font-size: 14px;
    line-height: 59px;
    color: #fff;
    text-decoration: none;
    position: relative;
    font-weight: 600;
    @media screen and (min-width: 1250px) {
      &:hover {
        background: #ff9400;
        text-decoration: none;
        border-color: #ff9400;
        color: #fff;
      }
    }
    &::after {
      content:'';
      position: absolute;
      top: 50%;
      margin-top: -7px;
      right: 40px;
      background: url('../images/arrow-btn.png') no-repeat;
      width: 9px;
      height: 13px;
    }
  }
}
.main-slider {
  &__slide {
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    min-height: 470px;
    background-position: 50% 50%;
    &::after {
      content:'';
      position: absolute;
      top:0;
      left:0;
      bottom: 0;
      right: 0;
      background: rgba(0,0,0,0.53);
    }
  }
  &__info {
    display: flex;
    align-items: center;
    min-height: 470px;
    max-width: 1140px;
    margin: 0 auto;
    position: relative;
    z-index: 1;
  }
  &__text {
    color: #fff;
    width: 100%;
  }
  &__title {
    font-size: 43px;
    font-weight: 700;
    line-height: 49px;
    max-width: 642px;
    margin-bottom: 30px;
  }
  &__btn {
    border: 2px solid #fff;
    border-radius: 30px;
    max-width: 246px;
    width: 100%;
    flex-basis: 246px;
    font-size: 14px;
    line-height: 59px;
    color: #fff;
    text-decoration: none;
    position: relative;
    font-weight: 600;
    display: block;
    text-align: center;
    @media screen and (min-width: 1250px) {
      &:hover {
        background: #ff9400;
        text-decoration: none;
        border-color: #ff9400;
        color: #fff;
      }
    }
    &::after {
      content:'';
      position: absolute;
      top: 50%;
      margin-top: -7px;
      right: 40px;
      background: url('../images/arrow-btn.png') no-repeat;
      width: 9px;
      height: 13px;
    }
  }
}
.slick-dots {
  margin: 0;
  padding: 0;
  list-style: none;
  position: absolute;
  bottom: 29px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  li {
    margin: 0 8px;
  }
  .slick-active {
    button {
      background: #fff;
    }
  }
  button {
    text-indent: -9999px;
    background: rgba(255,255,255,0.5);
    width: 9px;
    height: 9px;
    border-radius: 50%;
    padding: 0;
    border: none;
    display: block;
    cursor: pointer;
    outline: none;
    @media screen and (min-width: 1250px) {
      &:hover {
        background: #fff;
      }
    }
  }
}
.product-list {
  padding: 60px 0 120px 0;
  max-width: 1140px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
  @media screen and (max-width: 767px) {
    padding: 50px 15px;
  }
  &--category {
    .product-list__slider {
      display: flex;
      flex-wrap: wrap;
      @media screen and (max-width: 767px) {
        justify-content: center;
      }
    }
    .product-list__item {
      opacity: 1;
      visibility: visible;
      max-width: 292px;
      @media screen and (max-width: 1250px) {
        height: auto;
        border: 1px solid #eee;
        margin-bottom: 15px;
      }
    }
    .product-list__item-inner {
      @media screen and (max-width: 1250px) {
        height: auto;
        width: auto;
        padding: 20px;
      }
    }
  }
}
.product-list:hover {
  z-index: 2;
}
.product-list .slick-list {
  overflow: visible;
}
.product-list__title {
  font-size: 18px;
  color: #ff9400;
  font-weight: 600;
  margin-bottom: 30px;
}
.product-list__item {
  transition: 0.3s;
  height: 370px;
  position: relative;
  opacity: 0;
  visibility: hidden;
}
.product-list__item a {
  text-decoration: none;
}
.product-list__item a:hover {
  text-decoration: none;
}
.slick-active .product-list__item {
  opacity: 1;
  visibility: visible;
}
.product-list__item:hover {
  z-index: 999;
  -webkit-box-shadow: 0px -10px 11px 2px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px -10px 11px 2px rgba(0, 0, 0, 0.05);
  box-shadow: 0px -10px 11px 2px rgba(0, 0, 0, 0.05);
  background: #fff;
}
.product-list__item:hover .product-list__item-info {
  opacity: 1;
  visibility: visible;
}
.product-list__item-inner {
  width: 100%;
  height: 100%;
  padding: 20px 20px 0 20px;
  display: block;
}
.product-list__item-picture {
  margin-bottom: 30px;
  width: 100%;
  height: 215px;
  display: flex;
  justify-content: center;
}
.product-list__item-picture img {
  height: 100%;
  object-fit: contain;
}
.product-list__item-name {
  text-align: center;
  display: block;
  margin-bottom: 15px;
  font-size: 14px;
  color: #6d6d6d;
}
.product-list__item-rating {
  justify-content: space-between;
  width: 100px;
  margin: 0 auto;
  margin-bottom: 20px;
}
.product-list__item-rating img {
  width: 20%;
}
.product-list__item-price {
  display: block;
  text-align: center;
  font-size: 27px;
  font-weight: 600;
  color: #6d6d6d;
}
.product-list__item-info {
  transition: 0.3s;
  position: absolute;
  top: 100%;
  -webkit-box-shadow: 0px 10px 11px 2px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 10px 11px 2px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 10px 11px 2px rgba(0, 0, 0, 0.05);
  background: #fff;
  padding: 10px 20px 40px 20px;
  opacity: 0;
  visibility: hidden;
  @media screen and (max-width: 1250px) {
    opacity: 1;
    visibility: visible;
    position: relative;
    top: 0;
    box-shadow: none;
  }
}
.product-list__item-buy {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.product-list__item-buy .btn-balance {
  margin-left: 15px;
}
.product-list__item-desc {
  margin:0;
  padding:0;
  list-style: none;
}
.product-list__item-desc li {
  font-size: 14px;
  margin-bottom: 15px;
  color: #757575;
}
.product-list__item-desc li:last-child {
  margin-bottom: 0;
}
a:focus {
  outline: none;
}
.product-list .slick-next,
.product-list .slick-prev {
  position: absolute;
  top: 50%;
  z-index: 10;
  background: none;
  outline: 0;
  border: 0;
  cursor: pointer;
  font: initial;
  font-size: 0;
}
.product-list .slick-next {
  right: -10px;
  border: solid #b8b8b8;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 7px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  @media screen and (max-width: 767px) {
    right: 0;
  }
}
.product-list .slick-prev {
  left: -10px;
  border: solid #b8b8b8;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 7px;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  @media screen and (max-width: 767px) {
    left: 0;
  }
}
.btn-buy {
  width: 100%;
  height: 40px;
  background: #ff9400;
  border: 0;
  outline: 0;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  color: #fff;
  text-transform: uppercase;
  font-size: 14px;
  cursor: pointer;
  overflow: hidden;
  position: relative;
}
.btn {
  overflow: hidden;
  position: relative;
}
button {
  outline: none;
}
.btn:before {
  content: "";
  display: block;
  position: absolute;
  background: #fff;
  width: 60px;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 1s;
  filter: blur(30px);
  -webkit-filter: blur(30px);
  -ms-transform: translateX(-130px) skewX(-15deg);
  transform: translateX(-130px) skewX(-15deg);
  -webkit-transform: translateX(-130px) skewX(-15deg);
}
.btn:hover:before {
  -webkit-transform: translateX(200px) skewX(-15deg);
  -ms-transform: translateX(200px) skewX(-15deg);
  transform: translateX(200px) skewX(-15deg);
  opacity: 1;
  -o-transition: .7s;
  transition: .7s;
  -webkit-transition: .7s;
}
.btn:hover {
  cursor: pointer;
  background: #ff9400;
}
.btn-balance {
  border: 0;
  outline: 0;
  width: 35px;
  height: 30px;
  background: url('../images/balance.svg') no-repeat;
  background-size: contain;
  cursor: pointer;
}
.footer {
  background: #1c040a;
}
.footer-inner {
  padding: 55px 0;
  max-width: 1140px;
  margin: 0 auto;
}
.footer-inner__contact-list {
  margin:0;
  padding: 40px 0 0 15px;
  list-style: none;
}
.footer-inner__contact-list li {
  font-size: 14px;
  color: #757575;
  opacity: 0.7;
  margin-bottom: 15px;
}
.footer-inner__contact-list li:last-child {
  margin-bottom: 0;
}
.footer-inner__social-list {
  display: flex;
}
.footer-inner__social-list a {
  display: block;
  width: 45px;
  margin-right: 15px;
}
.footer-inner__social-list a:last-child {
  margin-right: 0;
}
.footer-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.custom-category {
    margin: 0;
    padding: 20px 0 0;
    list-style: none;
    color: #000;
}
.custom-category li + li {
    margin-top: 10px;
}
.custom-category a {
    display: block;
    border: 1px solid #eee;
    padding: 20px;
    color: #000;
    text-align: center;
}
@media screen and (min-width: 1250px) {
    .custom-category a:hover {
        background: #ecb682;
        color: #fff;
        text-decoration: none;
    }
}
.product-list .product-thumb .image {
    padding: 0;
}
.product-list .product-thumb .caption a {
    color: #000;
}
.product-layout.product-list {
    padding: 20px 0 0;
}
.pagination>.active>a, .pagination>.active>a:focus, .pagination>.active>a:hover, .pagination>.active>span, .pagination>.active>span:focus, .pagination>.active>span:hover {
    background: #ecb682;
    border-color: #ecb682;
}
.custom-content-box {
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    border-radius: 8px;
    padding: 10px 20px;
}
#column-left .ocfilter-option label a {
    color: #000;
    text-decoration: none;
    display: inline-block;
    vertical-align: middle;
    max-width: 200px;
}
.ocfilter-option .option-name {
    color: #ecb682;
    text-transform: uppercase;
}
#compare-total {
    background: #ecb682;
    color: #fff;
}
.table-responsive a {
    color: #ecb682;
}
.table-responsive input[type="text"].form-control {
    height: 34px;
}
#input-coupon {
    height: 37px;
}
.btn-primary {
    background: #ecb682;
    border-color: #ecb682;
}
#cart a {
    color: #ecb682;
    text-transform: uppercase;
}
